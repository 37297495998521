export const useViewsSettings = createSharedComposable(() => {
  const { useItems } = useCms();
  const { readSingleton } = useItems();

  const { data: settings } = useAsyncData(
    "views_settings",
    async () =>
      await readSingleton("views_settings", {
        fields: ["*", "*.*"],
      }),
    { immediate: true }
  );

  const getAuthViewImage = (view: MaybeRef<AuthView>) => {
    switch (unref(view)) {
      case AuthView.Login:
        return settings.value?.auth_login_image;
      case AuthView.Recover:
        return settings.value?.auth_recover_image;
      case AuthView.Reset:
        return settings.value?.auth_reset_image;
      case AuthView.Confirm:
        return settings.value?.auth_confirm_image;
      case AuthView.Register:
        return settings.value?.auth_register_image;
    }
  };

  const getContactPageImage = (success: MaybeRef<boolean>) => {
    return unref(success)
      ? settings.value?.page_contact_image_success
      : settings.value?.page_contact_image;
  };

  return { settings, getAuthViewImage, getContactPageImage };
});
